import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Card from "../components/Card.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import logo_consorzio from "../assets/img/logo_consorzio.svg";
import logo_sorbello from "../assets/img/logo_sorbello.svg";
import headerimg from "../assets/img/slide.jpg";
import Timing from "components/Timing.js";

import Form from "../components/Form";
const formConfig = {
  api: `${process.env.REACT_APP_API}/contact/index.php`,
  title: "Hai bisogno di altre informazioni?",
  description: "Compila questo modulo e ti ricontattiamo in 24 ore.",
  successMessage: "Grazie per averci contattato, ti risponderemo in 24 ore.",
  errorMessage: "Si è verificato un errore, controlla i dati inseriti e riprova.",
  fields: {
    name: "",
    email: "",
    message: "",
  },
  fieldsConfig: [
    { id: 1, label: "Nome e Cognome", fieldName: "name", type: "text", placeholder: "Nome e Cognome", isRequired: true, klassName: "" },
    { id: 2, label: "Email", fieldName: "email", type: "email", placeholder: " Indirizzo Email", isRequired: true, klassName: "" },
    { id: 3, label: "Messaggio", fieldName: "msg", type: "textarea", placeholder: "Scrivi qui il tuo messaggio.....", isRequired: true, klassName: "" },
  ],
};
export default function Landing() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={
              {
                // backgroundImage: `url('${headerimg}')`
              }
            }
          >
            <span id="blackOverlay" className="w-full h-full absolute opacity-100 bg-blue-900"></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <img src={logo_consorzio} className="w-auto" alt={"logo_consorzio"} />
                  <h1 className="text-white font-semibold text-4xl">Giarre | Mascali</h1>
                  <p className="mt-4 text-lg font-bold text-gray-200">
                    Scopri i nostri laboratori e punti prelievo. <br />
                    Dal 2011 in continua evoluzione!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden" style={{ height: "70px" }}>
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 w-full md:w-6/12 px-4 text-center">
                <Card
                  image={logo_sorbello}
                  title="Laboratorio Sorbello"
                  description="Laboratorio di Patologia Clinica Dr. A. Sorbello Srl <br />Via Garibaldi, 62 - 95014 Giarre (CT)"
                  color="blue"
                  actions={[
                    {
                      label: "Chiama",
                      action: () => {
                        window.location.href = "tel:+39095934639";
                      },
                    },
                    {
                      label: "E-Mail",
                      action: () => {
                        window.location.href = "mailto:giarre@centroanalisiclinichedelloionio.it?subject=[Contatto] - Centro Analisi Cliniche dello Ionio";
                      },
                    },
                  ]}
                >
                  <Timing
                    model={[
                      {
                        key: "Orari di Apertura",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "06:30 - 13:00",
                      },
                      {
                        key: "Sabato",
                        value: "06:30 - 12:00",
                      },
                      {
                        key: "Lunedì, Mercoledì e Venerdì Pomeriggio",
                        value: "15:00 - 17:00",
                      },
                      {
                        key: "Orari per i Prelievi",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "07:00 - 11:00",
                      },
                      {
                        key: "Sabato",
                        value: "07:00 - 11:00",
                      },
                      {
                        key: "Orari per le Consegne",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "12:00 - 13:00",
                      },
                      {
                        key: "Lunedì, Mercoledì e Venerdì Pomeriggio",
                        value: "15:00 - 17:00",
                      },
                    ]}
                  />
                </Card>
              </div>

              <div className="lg:pt-6 w-full md:w-6/12 px-4 text-center">
                <Card
                  // image={logo_consorzio}
                  title="Punto Prelievi Mascali"
                  description="Punto Prelievi Mascali (CT) Tel:+39 095 3286246"
                  color="green"
                  actions={[
                    {
                      label: "Chiama",
                      action: () => {
                        window.location.href = "tel:+390953286246";
                      },
                    },
                    {
                      label: "E-Mail",
                      action: () => {
                        window.location.href = "mailto:mascali@centroanalisiclinichedelloionio.it?subject=[Contatto] - Centro Analisi Cliniche dello Ionio";
                      },
                    },
                  ]}
                >
                  <Timing
                    model={[
                      {
                        key: "Orari di Apertura",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "07:30 - 12:00",
                      },
                      {
                        key: "Sabato",
                        value: "08:00 - 10:00",
                      },
                      {
                        key: "Orari per i Prelievi",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "07:30 - 10:30",
                      },
                      {
                        key: "Sabato",
                        value: "08:00 - 10:00",
                      },
                      {
                        key: "Orari per le Consegne",
                      },
                      {
                        key: "Da Lunedì a Venerdì",
                        value: "10:00 - 12:00",
                      },
                      {
                        key: "Martedì e Venerdì Pomeriggio",
                        value: "16:00 - 17:00",
                      },
                    ]}
                  />
                </Card>
              </div>
              {/* <div className="lg:pt-0 w-full md:w-4/12 px-4 text-center">
                <Card
                  image={logo_consorzio}
                  title="Laboratorio Arcidiacono"
                  description="Laboratorio Analisi Del Dott. Gaetano Arcidiacono & C. S.A.S.<br />Via Monsignor Genuardi, 7, 95024 Acireale (CT)"
                  color="red"
                  actions={[
                    {
                      label: 'Chiama',
                      action: () => {
                        window.location.href = 'tel:+39095605935'
                      },
                    },
                    {
                      label: 'E-Mail',
                      action: () => {
                        window.location.href = 'mailto:acireale@centroanalisiclinichedelloionio.it?subject=[Contatto] - Centro Analisi Cliniche dello Ionio'
                      },
                    },
                  ]}
                >
                  <Timing
                    model={[
                      {
                        key: "Lunedì - Venerdì",
                        value: "07:00 - 12:30",
                      },
                      {
                        key: "Martedì - Venerdì",
                        value: "07:00 - 18:30",
                      },
                      {
                        key: "Sabato",
                        value: "07:00 - 12:30",
                      },
                    ]}
                  />
                </Card>
              </div> */}
            </div>

            <div className="flex flex-wrap items-center mt-15">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                {/* <img src={logo_consorzio} className="w-auto" alt={"logo_consorzio"} /> */}
                <h3 className="text-2xl mb-2 font-semibold leading-normal">Centro Analisi Cliniche dello Ionio</h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Il Centro Analisi Cliniche dello Ionio S.C. a R.L. è un Consorzio di Laboratori di Analisi Cliniche nato nel 2011 ed in continua evoluzione.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  Standard Qualitativi Elevati, grazie alle risorse tecnologiche, all'avanguardia ed alle risorse umane di cui dispone il Consorzio, assicurano la notevole affidabilità delle analisi,
                  supportata dai controlli di qualità esterni effettuati con il <strong>CRQ Regione Sicilia.</strong>
                </p>
                <p className="text-lg front-light leading-relaxed mt-0 mb-4 text-gray-700">
                  Il personale specializzato dell’attività è a completa disposizione dell’utenza per fornire tutti i chiarimenti e le informazioni richieste.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blue-900">
                  <img alt="..." src={logo_consorzio} className="w-full align-middle rounded-t-lg" />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px",
                      }}
                    >
                      <polygon points="-30,95 583,95 583,65" className="text-blue-500 fill-current"></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">Top Quality Services</h4>
                    <p className="text-md font-light mt-2 text-white">
                      L'accreditamento è il riconoscimento formale della competenza di un laboratorio ad effettuare specifiche prove o determinati tipi di prova. Esso infatti è definito come il
                      "Procedimento con cui un organismo riconosciuto (ACCREDIA) attesta formalmente la competenza di un organismo o persona (nel nostro caso del laboratorio) a svolgere funzioni
                      specifiche".
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style={{ height: "80px" }}>
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img alt="..." className="max-w-full rounded-lg shadow-lg" src="doctor-portrait.jpg" />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">In costante aggiornamento</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    Il personale medico e tecnico afferente alla struttura è in possesso di qualifiche professionali riconosciute a livello regionale e nazionale ed è continuamente sottoposto a corsi
                    di perfezionamento per il miglioramento delle proprie conoscenze, nonché ad aggiornamenti professionali ECM (Educazione Continua in Medicina) che necessitano per la costante
                    evoluzione delle tecniche e delle metodologie applicate.
                  </p>
                  <h3 className="text-3xl font-semibold mt-10">Ambiti di lavoro</h3>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            {/* <i className="fas fa-fingerprint"></i> */}
                            <FontAwesomeIcon icon={solid("paper-plane")} />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Ematologia e Coagulazione</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            {/* <i className="fab fa-html5"></i> */}
                            <FontAwesomeIcon icon={solid("paper-plane")} />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Laboratorio Generale di Base</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            {/* <i className="far fa-paper-plane"></i> */}
                            <FontAwesomeIcon icon={solid("paper-plane")} />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Microbiologia</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <FontAwesomeIcon icon={solid("paper-plane")} />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Biologia Molecolare</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200 mr-3">
                            <FontAwesomeIcon icon={solid("paper-plane")} />
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Citologia</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-20 relative block bg-gray-900">
          <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style={{ height: "80px" }}>
            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
              <polygon className="text-gray-900 fill-current" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>

          <div id="sedi" className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">Il Consorzio | Le Sedi</h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">Attualmente il consorzio si compone di 3 sedi distribuite nel territorio Ionico-Etneo.</p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 mb-12 justify-center">
              <div className="w-full lg:w-6/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  {/* <i className="fas fa-medal text-xl"></i> */}
                  <FontAwesomeIcon icon={solid("map-marker")} size="xl" />
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">Giarre</h6>
                <p className="mt-2 mb-4 text-gray-500">
                  Laboratorio Sorbello <br />
                  Via Garibaldi, 62 - Giarre (CT) <br />
                  Tel: <a href="tel:+39095934639">+39 095 934639</a>
                  <br />
                  Email: <a href="mailto:infolabsorbello@gmail.com">infolabsorbello@gmail.com</a> <br />
                  Sito:{" "}
                  <a target="_blank" href="https://www.labsorbello.it">
                    www.labsorbello.it
                  </a>{" "}
                  <br />
                </p>
              </div>
              <div className="w-full lg:w-6/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  {/* <i className="fas fa-poll text-xl"></i> */}
                  <FontAwesomeIcon icon={solid("map-marker")} size="xl" />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">Mascali</h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Punto Prelievi <br />
                  {/* TODO: aggiungere la via */}
                  Mascali (CT) <br />
                  Tel:<a href="tel:+390953286246">+39 095 3286246</a>
                  <br />
                </p>
              </div>
              {/* <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <FontAwesomeIcon icon={solid("map-marker")} size="xl" />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">Acireale</h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Laboratorio Arcidiacono <br />
                  Via Monsignor Genuardi, 7 - Acireale (CT) <br />
                  Tel: <a href="tel:+39095605935">+39 095 605935</a>
                  <br />
                  Email: <a href="mailto:labanalisi.arcidiacono@gmail.com">labanalisi.arcidiacono@gmail.com</a> <br />
                </p>
              </div> */}
            </div>
          </div>
        </section>
        <section id="contatti" className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <Form config={formConfig} />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
