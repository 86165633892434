import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export default function Timing({model}) {
  return (
    <table width="100%">
      <tbody>
        {model.map((m, key) => (
          <tr key={`timing-${key}`}>
            <td><strong>{m.key}</strong></td>
            <td>{m.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
