import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import logo_consorzio from "../assets/img/logo_consorzio.svg";

export default function Footer() {
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6">
      <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style={{ height: "80px" }}>
        <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
          <polygon className="text-gray-300 fill-current" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-3/12 px-4">
            <img src={logo_consorzio} className="w-50" />

            {/* <h4 className="text-3xl font-semibold">
              Centro Analisi Cliniche dello Ionio S.C. a R.L.
            </h4> */}

            {/* <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Find us on any of these platforms, we respond 1-2 business days.
            </h5>
            <div className="mt-6">
              <button
                className="bg-white text-blue-400 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon className='flex' icon={brands('twitter')} size="xl" />
              </button>
              <button
                className="bg-white text-blue-600 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon className='flex' icon={brands('facebook')} size="xl" />

              </button>
              <button
                className="bg-white text-pink-400 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon className='flex' icon={brands('dribbble')} size="xl" />
              </button>
              <button
                className="bg-white text-gray-900 shadow-lg font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon className='flex' icon={brands('github')} size="xl" />
              </button>
            </div>  */}
          </div>
          <div className="w-full lg:w-9/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-3/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">Laboratori Analisi</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="//www.labsorbello.it" target="_blank">
                      Giarre
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="#sedi">
                      Mascali
                    </a>
                  </li>
                  {/* <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="#sedi">
                      Acireale
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="w-full lg:w-3/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">Legal</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="/privacy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="/cookie">
                      Cookie Policy
                    </a>
                  </li>

                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="/docs/3_Attestazione OdV_CENTRO_ANALI_DELLO_IONIO_A_R_L_signed.pdf">
                      Attestazione OdV
                    </a>
                  </li>

                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="/docs/3.1_All_2.4.Del.294.2021.GrigliaRilevazioneAssocFondazEntiDirittoPrivato_signed.pdf">
                      All.2 del 29/04/2021 Griglie di Rilevamento
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="/docs/13_Scheda di sintesi OdV_CENTRO_ANALISI_DELLO_IONIO_A_R_L_signed.pdf">
                      Scheda di Sintesi OdV
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" href="https://cacdi.safereport.it/#/">
                      Whistleblowing
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">Docs</span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/codice_etico.pdf">
                      Codice Etico
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/allegato_2.4.xlsx">
                      All. 2.4 del 294/2021
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/manuale_sgra.pdf">
                      Manuale SGRA
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/piano_anticorruzione.pdf">
                      Piano Anticorruzione
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/accesso_civico.pdf">
                      Accesso Civico
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/attestazione_odv.pdf">
                      Attestazione ODV
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm" target="_blank" href="/docs/informativa_videosorveglianza.pdf">
                      Informativa Videosorveglianza
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-12/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()} CENTRO ANALISI CLINICHE DELLO IONIO S.C. A R.L. by{" "}
              <a target="_blank" href="https://www.splitweb.it" className="text-gray-600 hover:text-yellow-500">
                SPLIT Web Agency Giarre Catania
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
