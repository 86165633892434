import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import { Link } from 'react-router-dom';

export default function Cookie() {
  return (
    <>
      <Navbar />
      <section className='relative py-20'>
        <div className='container mx-auto px-4 prose-xl'>
          <h1 className='font-semibold text-4xl text-center'>Cookie Policy</h1>
          <h2 className='font-semibold text-2xl mt-2 italic'>Introduzione</h2>
            <p className='mb-3 text-lg md:text-xl'>Benvenuti su centroanalisiclinichedelloionio.it. Questa Cookie Policy spiega come e perché utilizziamo i cookie sul nostro sito web. Il nostro obiettivo è fornirvi la migliore esperienza utente possibile, garantendo al contempo la vostra privacy e il rispetto delle normative vigenti.</p>
          <h2 className='font-semibold text-2xl mt-2 italic'>Cosa Sono i Cookie?</h2>
            <p className='mb-3 text-lg md:text-xl'>I cookie sono piccoli file di testo che i siti web possono usare per rendere l'esperienza dell'utente più efficiente. La legge afferma che possiamo memorizzare cookie sul vostro dispositivo se sono strettamente necessari per il funzionamento di questo sito. Per tutti gli altri tipi di cookie, abbiamo bisogno del vostro permesso.</p>
          <h2 className='font-semibold text-2xl mt-2 italic'>I Cookie Tecnici</h2>
            <p className='mb-3 text-lg md:text-xl'>Il nostro sito web utilizza esclusivamente cookie tecnici, che sono necessari per il corretto funzionamento del sito e per fornire i servizi richiesti dagli utenti. Questi cookie sono essenziali per navigare nel sito e utilizzare le sue funzionalità, come l'accesso a aree protette del sito.</p>
          <h2 className='font-semibold text-2xl mt-2 italic'>Tipi di Cookie Tecnici Utilizzati</h2>
            <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li>Cookie di Sessione: Questi cookie sono temporanei e scadono una volta che chiudete il browser.</li>
              <li>Cookie Persistenti: Questi cookie rimangono sul vostro dispositivo per un periodo di tempo più lungo o fino a quando non vengono cancellati manualmente.</li>
              <li>Cookie Funzionali: Consentono al sito di ricordare le scelte che avete fatto (come la lingua o la regione) e offrono funzionalità avanzate, personalizzate.</li>
            </ul>
          <h2 className='font-semibold text-2xl mt-2 italic'>Gestione dei Cookie</h2>
            <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li>Impostazioni del Browser: Potete controllare e/o cancellare i cookie come desiderate. Potete cancellare tutti i cookie che sono già sul vostro computer e potete impostare la maggior parte dei browser per impedirne l'installazione. Tuttavia, se lo fate, potreste dover regolare manualmente alcune preferenze ogni volta che visitate un sito e alcuni servizi e funzionalità potrebbero non funzionare.</li>
              <li>Consensi Specifici: Non essendo utilizzati cookie di profilazione o di marketing, non è necessario il vostro consenso esplicito.</li>
            </ul>
          <h2 className='font-semibold text-2xl mt-2 italic'>Perché Utilizziamo i Cookie Tecnici</h2>
            <p className='mb-3 text-lg md:text-xl'>I cookie tecnici sono utilizzati per:</p>
            <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li>Assicurare una navigazione e un'esperienza utente efficienti e sicura.</li>
              <li>Riconoscere il vostro dispositivo al ritorno sul nostro sito, facilitando la vostra navigazione.</li>
              <li>Fornire servizi essenziali come la sicurezza del sito e l'assistenza al cliente.</li>
            </ul>
          <h2 className='font-semibold text-2xl mt-2 italic'>Privacy e Protezione dei Dati</h2>
            <p className='mb-3 text-lg md:text-xl'>Rispettiamo la vostra privacy e ci impegniamo a proteggere i vostri dati personali. Per maggiori informazioni su come gestiamo i vostri dati, si prega di consultare la nostra
            <Link className='bg-blue-900 text-white hover:text-gray-800 hover:bg-gray-100 hover:border-blue-900 hover:border-2 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3'
                  to="/privacy"
            >Informativa sulla Privacy</Link>
            </p>
          <h2 className='font-semibold text-2xl mt-2 italic'>Modifiche alla Cookie Policy</h2>
            <p className='mb-3 text-lg md:text-xl'>Questa Cookie Policy può essere aggiornata periodicamente per riflettere, ad esempio, cambiamenti ai cookie che utilizziamo o per altri motivi operativi, legali o normativi. Vi invitiamo a rivedere periodicamente questa Cookie Policy per rimanere informati su come utilizziamo i cookie.</p>
          <h2 className='font-semibold text-2xl mt-2 italic'>Contatti</h2>
            <p className='mb-3 text-lg md:text-xl'>Per qualsiasi domanda relativa a questa Cookie Policy, potete contattarci all’indirizzo labsorbello@gmail.it.</p>

          <p className='mb-3 italic text-lg md:text-xl'>Data dell'Ultimo Aggiornamento: 15/01/2024</p>
        </div>
      </section>
      <Footer />
    </>
  );
}
