import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

export default function Card({image, title, description, color, children, actions}) {
  return (
    <div className={`relative flex flex-col min-w-0 break-words bg-white border border-${color}-500 w-full mb-8 shadow-lg rounded-lg`}>
      <div className="px-4 py-5 flex-auto">
        {/* <FontAwesomeIcon icon={solid('user-secret')} /> */}
        {image && <img className="w-screen max-h-16" src={image} alt={title} />}
        <h6 className="text-xl font-semibold">{title}</h6>
        <p className="mt-2 mb-4 text-gray-600" dangerouslySetInnerHTML={{__html: description}} />

        {children}

        {actions.map(({label, action}, key) => (
          <button
            key={`card-action-${key}`}
            className={`bg-transparent hover:bg-${color}-500 text-${color}-700 font-semibold hover:text-white border-${color}-500 hover:border-transparent rounded py-2 px-4 m-2 border`}
            onClick={action}
            type="button"
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}
