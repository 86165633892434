import React from 'react';
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { Link } from 'react-router-dom';

export default function Privacy() {
  return (
    <>
      <Navbar />
      <section className='relative py-20'>
        <div className='container mx-auto px-4 prose-xl'>
          <h1 className='font-semibold text-4xl text-center'>Pagina privacy</h1>
          <h2 className='font-semibold text-2xl text-center'>INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI</h2>
          <h3 className='font-semibold italic text-center mb-6'>degli utenti che consultano il sito web <strong>centroanalisiclinichedelloionio.it</strong></h3>

          <p className='mb-3 text-lg md:text-xl'>Ai sensi dell'art.13 del Regolamento UE 2016/679 del 27 aprile 2016 (GDPR) desideriamo fornire, in qualità di titolari del trattamento, opportuna informativa circa le modalità di trattamento dei dati personali degli utenti che interagiscono con i servizi web forniti da <strong>Centro Analisi Cliniche dello Ionio Scarl</strong>, e accessibili per via telematica a partire dall'indirizzo: <strong>https://www.centroanalisiclinichedelloionio.it</strong>.</p>
          <p className='mb-3 text-lg md:text-xl'>L'informativa non riguarda altri siti, pagine o servizi online, raggiungibili tramite link ipertestuali eventualmente pubblicati nel sito ma riferiti a risorse esterne ad esso.</p>
          <p className='mb-3 text-lg md:text-xl'>Specifiche informative di sintesi, saranno progressivamente riportate o, consultabili nelle pagine del sito riservate a particolari servizi richiesti dall'utente.</p>
          <p className='mb-3 text-lg md:text-xl'><strong>Titolare del trattamento<em> </em></strong>è <strong>Centro Analisi Cliniche dello Ionio Scarl</strong> con sede legale in Via Garibaldi, 62 95014 Giarre (CT).</p>

          <h2 className='font-semibold text-2xl mt-2 italic'>Tipi di dati trattati</h2>
          <p className='mb-3 text-lg md:text-xl'>I dati trattati possono essere di due tipi:</p>
          <ol className='list-[upper-alpha] list-inside marker:font-bold mb-3 text-lg md:text-xl'>
              <li >
                <strong>Dati di Navigazione</strong> la cui acquisizione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP, il tipo di browser e alcune informazioni sulle caratteristiche del device (pc, tablet o smartphone) utilizzato per la navigazione, nonché dati raccolti utilizzando i cookie o tecnologie similari.
                <p className='mb-3 text-lg md:text-xl'>Tali dati, utili per la fruizione dei servizi web, vengono anche trattati allo scopo di:</p>
                <ul className='list-disc list-inside pl-4 mb-3 text-lg md:text-xl'>
                  <li>ottenere informazioni statistiche sull'uso dei servizi (pagine più visitate, numero di visitatori per fascia oraria o giornaliera, aree geografiche di provenienza, ecc.);</li>
                  <li>controllare il corretto funzionamento dei servizi offerti.</li>
                </ul>
              </li>
              <li>
                <strong>Dati comunicati dall'Utente </strong>che ha la facoltà di fornire in via esplicita e volontaria.
                <p className='mb-3 text-lg md:text-xl'>In questa categoria di dati, rientrano:</p>
                <ul className='list-disc list-inside pl-4 mb-3 text-lg md:text-xl'>
                  <li>Dati anagrafici (quali nome, cognome, data di nascita, età, sesso, ecc.)</li>
                  <li>Dati di contatto (e-mail, indirizzo, numero di telefono)</li>
                </ul>
              </li>
          </ol>

          <h2 className='font-semibold text-2xl mt-2 italic'>Finalità e Base Giuridica del trattamento:</h2>
          <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li ><strong><em>Contatti:</em></strong> I dati personali saranno raccolti al fine di dare risposte alle richieste che vengono inviate attraverso i form di contatto presenti sul sito.</li>
              <li ><strong><em>Base Giuridica: </em></strong>Misure precontrattuali di cui l'interessato è parte.</li>
          </ul>

          <h2 className='font-semibold text-2xl mt-2 italic'>Natura del Conferimento</h2>
          <p className='mb-3 text-lg md:text-xl'>Il conferimento dei Suoi Dati Personali ha natura facoltativa. Tuttavia, la loro mancata comunicazione potrebbe pregiudicare la possibilità di usufruire di alcuni servizi.</p>
          <p className='mb-3 text-lg md:text-xl'>La natura obbligatoria o facoltativa del conferimento sarà di volta in volta segnalata mediante l'uso di simboli (ad es. &ldquo;*&rdquo;) posti al fianco dell'informazione il cui conferimento è obbligatorio per perseguire la rispettiva finalità</p>

          <h2 className='font-semibold text-2xl mt-2 italic'>Modalità di trattamento</h2>
          <p className='mb-3 text-lg md:text-xl'>I Suoi Dati Personali saranno trattati in conformità alle prescrizioni della vigente Normativa Privacy, con modalità e strumenti in grado di rispettare le misure di sicurezza di cui all'art. 32 del GDPR, sia con l'ausilio di mezzi elettronici ed automatizzati sia con modalità manuali, con logiche strettamente correlate alle finalità del trattamento, mediante i data base, le piattaforme elettroniche gestite da <strong>Centro Analisi Cliniche dello Ionio Scarl</strong> o da terzi (nominati Responsabili del trattamento)<strong>.</strong></p>

          <h2 className='font-semibold text-2xl mt-2 italic'>Tempi di conservazione</h2>
          <p className='mb-3 text-lg md:text-xl'>I Suoi Dati Personali saranno conservati per il tempo strettamente necessario a conseguire gli scopi per cui sono raccolti. Al fine di assicurare il rispetto dei principi di necessità e proporzionalità del trattamento, sono stati individuati diversi periodi di conservazione dei Dati Personali in relazione alle finalità perseguite:</p>
          <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li >I dati raccolti a fini di gestione e di risposta a richieste in relazione ai servizi offerti da <strong>Centro Analisi Cliniche dello Ionio Scarl </strong>ed i dati forniti nell'interazione con <strong>Centro Analisi Cliniche dello Ionio Scarl</strong> verranno conservati per il periodo strettamente necessario ad evadere le richieste e successivamente cancellati ove non intercorra alcun ulteriore contatto.</li>
          </ul>

          <h2 className='font-semibold text-2xl mt-2 italic'>Destinatari dei dati</h2>
          <p className='mb-3 text-lg md:text-xl'>I dati personali raccolti possono essere comunicati a Soggetti Terzi, designati da <strong>Centro Analisi Cliniche dello Ionio Scarl</strong> Responsabili del Trattamento, la cui attività è connessa ai servizi offerti da questo Sito Web. I dati saranno altres&igrave; trattati dal personale di <strong>Centro Analisi Cliniche dello Ionio Scarl</strong>, che agisce sulla base di specifiche istruzioni fornite in ordine alle finalità e modalità del trattamento medesimo.</p>

          <h2 className='font-semibold text-2xl mt-2 italic'>Diritti dell'interessato</h2>
          <p className='mb-3 text-lg md:text-xl'>In qualità di interessato ha i seguenti diritti:</p>
          <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li ><strong>Diritto di accesso:</strong> diritto di ottenere dal titolare la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l'accesso ai dati personali, una copia degli stessi, e ricevere informazioni relative al trattamento;
                <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_91_Diritto_di_Accesso_Rev_2005.pdf'>
                  Scarica il file
                  <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                </a>
              </li>
              <li ><strong>Diritto di rettifica:</strong> diritto di ottenere dal titolare la rettifica dei dati personali inesatti che lo riguardano senza ingiustificato ritardo e l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa;
                <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_96_Diritto_di_Rettifica_Rev_2005.pdf'>
                  Scarica il file
                  <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                </a>
              </li>
              <li ><strong>Diritto di cancellazione:</strong> diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo se sussiste uno dei motivi seguenti:
              <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_92_Diritto_di_Cancellazione_Rev_2005.pdf'>
                Scarica il file
                <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
              </a>
                  <ul className='list-disc list-inside pl-4 mb-3 text-lg md:text-xl'>
                      <li >i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;</li>
                      <li >l'interessato revoca il consenso su cui si basa il trattamento e non sussiste altro fondamento giuridico per il trattamento;</li>
                      <li >l'interessato si oppone al trattamento e non sussiste alcun motivo legittimo prevalente per procedere al trattamento;</li>
                      <li >i dati personali sono stati trattati illecitamente;</li>
                      <li >i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dallo Stato membro cui è soggetto il titolare del trattamento;</li>
                      <li >i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione.</li>
                  </ul>
              </li>
              <li ><strong>Diritto di limitazione del trattamento:</strong> diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:
                <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_93_Diritto_di_Limitazione_Rev_2005.pdf'>
                  Scarica il file
                  <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                </a>
                <ul className='list-disc list-inside pl-4 mb-3 text-lg md:text-xl'>
                    <li >l'interessato contesta l'esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali dati personali;</li>
                    <li >il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l'utilizzo;</li>
                    <li >benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all'interessato per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</li>
                    <li >l'interessato si è opposto al trattamento in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell'interessato.</li>
                </ul>
              </li>
              <li ><strong>Diritto alla portabilità:</strong> diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti al Titolare e di trasmettere tali dati a un altro titolare del trattamento qualora il trattamento si basi sul consenso o sul contratto e sia effettuato con mezzi automatizzati.
                <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_95_Diritto_di_Portabilita_Rev_2005.pdf'>
                  Scarica il file
                  <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                </a>
              </li>
              <li ><strong>Diritto di opposizione:</strong> diritto di opporsi al trattamento effettuato per l'esecuzione di un compito di interesse pubblico o connesso all'esercizio di pubblici poteri o sulla base del legittimo interesse del titolare o di terzi, nonché il diritto di opporsi al trattamento dei dati personali che lo riguardano effettuato per finalità di marketing diretto, non prestando il consenso inizialmente o revocandolo successivamente (con l'avvertenza che l'eventuale successiva revoca del consenso non pregiudica la liceità del trattamento svolto prima di essa).
                <a className='inline-flex items-center px-2 py-1 ml-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    target='_blank'
                    href='/docs/diritti/05_94_Diritto_di_Opposizione_Rev_2005.pdf'>
                  Scarica il file
                  <svg class="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                </a>
              </li>
          </ul>

          <p className='mb-3 text-lg md:text-xl'>L'esercizio dei diritti di cui sopra pu&ograve; essere inoltre ritardato, limitato o escluso nei casi previsti dall'art. 2-undecies d.lgs. 196/2003. Tali richieste potranno essere indirizzate a <strong>Centro Analisi Cliniche dello Ionio Scarl</strong>, all'indirizzo postale della sede legale o all'indirizzo mail <a href="mailto:labsorbello@gmail.com">labsorbello@gmail.com</a>. Lei riceverà riscontro il prima possibile e, in ogni caso, non oltre 30 giorni dalla Sua richiesta.</p>
          <ul className='list-disc list-inside space-y-1  mb-3 text-lg md:text-xl'>
              <li ><strong>Proporre reclamo al Garante per la protezione dei dati personali:</strong> qualora Lei desideri proporre un reclamo in merito alle modalità attraverso cui i Suoi dati personali sono trattati dal Titolare, ovvero in merito alla gestione di un reclamo da Lei proposto, Lei ha il diritto di presentare un'istanza direttamente all'Autorità di controllo secondo le modalità indicate nel sito <a href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>. </li>
          </ul>

          <h2 className='font-semibold text-2xl mt-2 italic'>Cookies</h2>
          <Link className='bg-blue-900 text-white hover:text-gray-800 hover:bg-gray-100 hover:border-blue-900 hover:border-2 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3' to="/cookie">Informativa sui Cookie</Link>
          <p className='mb-3 text-lg md:text-xl'>La presente <strong>Privacy Policy</strong> potrà essere soggetta ad aggiornamenti periodici senza nessun obbligo di preavviso.</p>
          <p className='mb-3 text-lg md:text-xl'>Per ogni informazione in relazione alla presente informativa privacy, è possibile contattare il Titolare, in qualsiasi momento inviando un messaggio di posta elettronica all'indirizzo <a href="mailto:labsorbello@gmail.com">labsorbello@gmail.com</a>.</p>

          <p className='mb-3 italic text-lg md:text-xl'>Data ultima revisione 15/01/2024</p>
        </div>
      </section>

      <Footer />
    </>
  );
}
