import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import "./styles.css";

/**
 * @component Form
 * @props - { object } -  config
 */
const Form = (props) => {
    const [mailSent, setmailSent] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});

    /**
    * @function handleFormSubmit
    * @param e { obj } - form event
    * @return void
    */
    const handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API}`,
            headers: { "content-type": "application/json" },
            data: formData
        })
            .then(result => {
                if (result.data.sent) {
                    setmailSent(result.data.sent)
                    setError(false)
                } else {
                    setError(true)
                }
            })
            .catch(error => setError(error.message));
    };
    /**
      * @function handleChange
      * @param e { obj } - change event
      * @param field { string } - namve of the field
      * @return void
      */
    const handleChange = (e, field) => {
        let value = e.target.value;
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const { title, description, successMessage, errorMessage, fieldsConfig } = props.config;
    return (
        <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                    <div className="flex-auto p-5 lg:p-10">
                        <h4 className="text-2xl font-semibold">{title}</h4>
                        <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                            {description}
                        </p>
                        <form action="#">
                            {fieldsConfig &&
                                fieldsConfig.map(field => {
                                    return (
                                        <React.Fragment key={field.id}>
                                            <div className="relative w-full mb-3">
                                                {field.type !== "textarea" ? (
                                                    <React.Fragment>
                                                        <label
                                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                            htmlFor={field.name}
                                                        >
                                                            {field.label}
                                                        </label>
                                                        <input
                                                            type={field.type}
                                                            className={"border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full " + field.klassName}
                                                            placeholder={field.placeholder}
                                                            value={field.name}
                                                            onChange={e => handleChange(e, field.fieldName)}
                                                        />

                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <label
                                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                            htmlFor={field.name}
                                                        >
                                                            {field.label}
                                                        </label>
                                                        <textarea
                                                            rows="4"
                                                            cols="80"
                                                            className={"border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full " + field.klassName}
                                                            placeholder={field.placeholder}
                                                            onChange={e => handleChange(e, field.fieldName)}
                                                            value={field.name}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            {/* <p className="font-bold text-sm mt-3">GDPR Agreement *</p> */}
                            <div className="flex items-center space-x-2 mt-2">
                                <input
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    name="privacy-consent"
                                    id="privacy-consent"
                                />
                                <label for="privacy-consent" className="ms-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                                  Confermo di essere maggiorenne e acconsento al trattamento dei dati personali ai sensi del Regolamento UE nr. 679/2016. Confermo anche di aver preso visione della
                                  <a href="/privacy" className="text-blue-600 dark:text-blue-500 hover:underline px-2" target="_blank">Informativa sulla Privacy</a>
                                </label>
                            </div>
                            <div className="text-center mt-6">
                                <button
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{ transition: "all .15s ease" }}
                                    onClick={e => handleFormSubmit(e)} value="Submit"
                                >
                                    Invia Messaggio
                                </button>
                            </div>
                            <div>
                                {mailSent && <div className="sucsess">{successMessage}</div>}
                                {error && <div className="error">{errorMessage}</div>}
                            </div>
                        </form>
                    </div>
                </div>
              </div>
            </div>
    );
}

export default Form;
//propTypes for the component
Form.propTypes = {
    config: PropTypes.object.isRequired
};
